













































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";
import { SearchHistoryEntry } from "@/assets/classes/SearchHistory.ts";
import { Vehicle } from "@/assets/classes/Vehicle.ts";
import PrintableSearchResults from "@/components/PrintableSearchResults.vue";
import { CreditCard } from "@/types";

const RH = new RequestHandler();

@Component({
  components: {
    PrintableSearchResults
  }
})
export default class SearchHistory extends Vue {
  private searchHistory: SearchHistoryEntry[] = [];
  private searchHistoryPanel = 0;
  private pastSearchesSearchText = "";
  private page = 1;
  private tableHeaders = [
    { text: "VIN", value: "vin" },
    { text: "Plate Number", value: "plateNo" },
    { text: "Make", value: "make" },
    { text: "Model", value: "model" },
    { text: "Year", value: "year" },
    { text: "Search Date", value: "searchDate" },
    { text: "Print", value: "" },
    { text: "Stolen", value: "stolen" },
    { text: "User", value: "user" }
  ];
  private searchID = -1;

  private creditCardDialog = false;
  private duplicateDialog = false;
  private vinSearch = false;
  private allowDuplicateSearch = false;
  private loadingSearch = false;

  private searchObject = {
    plate: "",
    vin: ""
  };

  private monthBoxItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  private yearBoxItems: number[] = [];
  private stateBoxItems = [];
  private mvdsSearchFee = 0;
  private fisServicePercentage = 0;
  private totalCharge = 0;
  private totalChargeLabel = "";

  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    zipcode: (value: string) => {
      const pattern = /^\d{5}(-\d{4})?$/;
      return pattern.test(value) || "Invalid zip code.";
    }
  };

  private creditCard = {
    name: "",
    creditCardNum: "",
    expMonth: 0,
    expYear: 0,
    cvv: "",
    billingAddress: {
      address: "",
      city: "",
      state: "",
      zip: ""
    }
  } as CreditCard;
  private creditCardFormValid = false;
  private useEmployerInfo = false;
  private printVehicle = {} as Vehicle;
  private searchTime = new Date();

  get creditCardForm(): Vue & { validate: () => boolean } {
    return this.$refs.creditCardForm as Vue & { validate: () => boolean };
  }

  public async print(searchID: number) {
    try {
      await (this.printVehicle = new Vehicle(
        await RH.getPreviousSearch(searchID)
      ));
      window.print();
    } catch (e) {
      this.$root.$emit("snackbar-message", "Printing Error");
    }
  }

  public onlyAllowNumbers(event: any): void {
    const charCode = event.keyCode;
    const valueString = String.fromCharCode(charCode);
    const value = parseInt(valueString);
    if (isNaN(value)) {
      event.preventDefault();
    }
  }
  public async checkForPayment() {
    this.loadingSearch = true;
    this.vinSearch = this.searchObject.vin.length > 0;
    if (!this.allowDuplicateSearch && this.checkForDuplicate()) {
      this.loadingSearch = false;
      this.duplicateDialog = true;
    } else {
      let hasACH: boolean;
      let isFreeAccount: boolean;
      try {
        const userCustomerInfo = await RH.getUserCustomerInfo(
          this.$store.getters.getUserID
        );
        hasACH =
          ![null, ""].includes(userCustomerInfo.accountNo) &&
          ![null, ""].includes(userCustomerInfo.routingNo);
        isFreeAccount = userCustomerInfo.isFreeAccount;
      } catch (e) {
        console.error(e);
        hasACH = false;
        isFreeAccount = false;
      }
      if (hasACH || isFreeAccount) {
        this.search();
      } else {
        this.creditCardDialog = true;
      }
    }
  }
  public async openResultFromHistory(
    selectedSearchHistoryEntry: SearchHistoryEntry
  ): Promise<void> {
    this.loadingSearch = true;
    this.$root.$emit("start-loading-overlay");
    let searchResult;
    this.vinSearch =
      selectedSearchHistoryEntry.vin !== null &&
      selectedSearchHistoryEntry.vin !== "";
    try {
      searchResult = await RH.getPreviousSearch(
        selectedSearchHistoryEntry.searchID
      );
      this.handleSearchResults(searchResult);
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "An error has occurred while opening search from history. Please try again."
      );
      this.$root.$emit("stop-loading-overlay");
      this.loadingSearch = false;
    } finally {
      Object.assign(this.searchObject, {
        vin: "",
        plate: ""
      });
    }
  }
  public async search() {
    this.creditCardDialog = false;
    this.loadingSearch = true;
    this.$root.$emit("start-loading-overlay");
    try {
      let searchResult = {};
      searchResult = await RH.getVehicle(
        this.searchObject.vin.trim(),
        this.searchObject.plate,
        this.creditCard
      );
      this.handleSearchResults(searchResult);
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "An error has occurred while searching. Please try again."
      );
      this.loadingSearch = false;
      if (this.creditCard.creditCardNum != "") {
        this.creditCardDialog = true;
      }
    } finally {
      this.$root.$emit("stop-loading-overlay");
      this.allowDuplicateSearch = false;
    }
  }
  private handleSearchResults(response: any): void {
    localStorage.setItem("resultVehicle", JSON.stringify(response));
    let stolen = false;
    let nmvtisState = "";

    const searchedVehicle = this.vinSearch ? response.vin : response.plateNo;

    if (response.searchResult) {
      if (
        !response.searchResult?.vehicleRecord &&
        response.searchResult?.nmvtisResponse &&
        !response.searchResult?.nmvtisResponse.hasTheftData
      ) {
        nmvtisState = response.searchResult.nmvtisResponse.titleState;
      }

      stolen =
        response.statusCode === "H" ||
        response.statusCode === "S" ||
        response.statusCode === "T";
    }

    if (response.searchResult?.vehicleRecord && !stolen && nmvtisState === "") {
      this.goToSearchResults(searchedVehicle);
    } else {
      this.searchID = response.searchID;
      localStorage.setItem("searchID", String(this.searchID));
      this.goToNoResults(searchedVehicle, stolen, nmvtisState);
    }
  }
  public goToSearchResults(searchedVehicle: string): void {
    this.$router.push({
      name: "SearchResults",
      params: { vehicle: searchedVehicle }
    });
  }
  public goToNoResults(
    searchedVehicle: string,
    stolenVehicle: boolean,
    nmvtisState: string
  ): void {
    this.$router.push({
      name: "NoResults",
      params: {
        vehicle: searchedVehicle,
        searchID: String(this.searchID),
        stolen: stolenVehicle ? "Stolen" : "",
        nmvtis: nmvtisState
      }
    });
  }
  private async toggleEmployerInfo() {
    if (this.useEmployerInfo) {
      const employer = await RH.getUserCustomerInfo(
        this.$store.getters.userInfo.userID
      );
      this.creditCard.billingAddress.address =
        employer.vtrsCustomer.physicalAddress.streetNo +
        " " +
        employer.vtrsCustomer.physicalAddress.address1;
      this.creditCard.billingAddress.city =
        employer.vtrsCustomer.physicalAddress.city;
      this.creditCard.billingAddress.state =
        employer.vtrsCustomer.physicalAddress.state;
      this.creditCard.billingAddress.zip =
        employer.vtrsCustomer.physicalAddress.zip5;
      this.creditCard.name = employer.primaryUser.fullname;
    } else {
      this.creditCard.billingAddress.address = "";
      this.creditCard.billingAddress.city = "";
      this.creditCard.billingAddress.state = "";
      this.creditCard.billingAddress.zip = "";
      this.creditCard.name = "";
    }
  }
  private checkForDuplicate(): boolean {
    return (
      this.searchHistory.find(item => {
        if (this.vinSearch) {
          return (
            item.vin !== null &&
            item.vin.toLowerCase() === this.searchObject.vin.toLowerCase()
          );
        } else {
          return (
            item.plateNo !== null &&
            item.plateNo.toLowerCase() === this.searchObject.plate.toLowerCase()
          );
        }
      }) !== undefined
    );
  }
  private enableDuplicateSearch() {
    this.allowDuplicateSearch = true;
    this.duplicateDialog = false;
    this.checkForPayment();
  }
  private declineDuplicateSearch() {
    Object.assign(this.searchObject, {
      vin: "",
      plate: ""
    });
    this.duplicateDialog = false;
  }
  private closeCreditCardDialog() {
    this.allowDuplicateSearch = false;
    this.loadingSearch = false;
    this.creditCardDialog = false;
    Object.assign(this.searchObject, {
      vin: "",
      plate: ""
    });
  }

  public setYearBoxItems(): void {
    this.yearBoxItems.push(new Date().getFullYear() - 2000);
    for (let i = 1; i <= 15; i++) {
      this.yearBoxItems.push(this.yearBoxItems[0] + i);
    }
  }

  async created() {
    this.setYearBoxItems();
    try {
      this.stateBoxItems = await RH.getStates();
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "The state dropdown has failed to populate"
      );
    }
    try {
      this.mvdsSearchFee = (await RH.getMVDSSeachFee())[0].feeAmount;
      this.fisServicePercentage = (
        await RH.getFISConvenienceFee()
      )[0].feeAmount;
      this.totalChargeLabel =
        "Total with " + this.fisServicePercentage + "% Service Fee";
      this.totalCharge =
        this.mvdsSearchFee +
        Math.round(this.mvdsSearchFee * this.fisServicePercentage) / 100;
    } catch (e) {
      this.$root.$emit("snackbar-message", "FIS fee retrieval has failed");
    }

    try {
      const response = await RH.getSearchHistory();
      if (response.length > 0) {
        response.forEach((historyEntry: { [key: string]: any } | undefined) => {
          try {
            this.searchHistory.push(new SearchHistoryEntry(historyEntry));
          } catch (e) {
            this.$root.$emit(
              "snackbar-message",
              "Failed to load search history entry"
            );
          }
        });
      }
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Search history has failed to populate"
      );
    }
  }
}
